import React, { useState } from 'react';
import "../format.css";
import balloon from "../images/fairOpp.jpg"
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import aiImage from "../images/fairOpp.jpg";
import loading from "../images/typing.gif";

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccessful, setEmailSuccessful] = useState(null);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");


  const updateEmail = (emailValue) => {
    setEmail(emailValue);
    if (emailValue === "") {
      setShowEmailError(true);
      setEmailErrorMsg("Email required");
    } else {
      setShowEmailError(false);
      setEmailErrorMsg("");
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setEmailErrorMsg("");
    setShowEmailError(false);
    setEmailLoading(true);

    try {
      const formData = {
        email: email,
      }
      const response = await fetch(props.backendURL + "forgot_password", {
        method: "POST", 
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })

      const data = await response.json()

      if (response.ok) {
        setEmailLoading(false);
        setEmail('');
        setEmailSuccessful(true);
      }
      else {
        if (data.email_error) {
          setEmailErrorMsg(data.msg);
          setShowEmailError(true);
        }
        setEmailSuccessful(false);

        // Timeout for smoother UI experience 
        setTimeout(() => {
          setEmailLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        setEmailErrorMsg("Unexpected error, please try again later");
        setShowEmailError(true);

        // Timeout for smoother UI experience
        setTimeout(() => {
          setEmailLoading(false);
        }, 1000);
      }
    }
  };

  if (emailLoading) {
    return (
      <div className="chat-loading-container">  
      <img className="chat-loading-logo" src={aiImage}></img>
      <img className="chat-loading-dots" src={loading}></img> 
      <h2 className="chat-loading-h2">Trying to email you a password reset link... One moment please!</h2>
    </div>
    )
  }

  if (emailSuccessful === true) {
    return (
      <div className="resetLinkSentMain">
        <img className="balloonImage" src={balloon}></img>
        <h1 className="resetLinkSent">Success!</h1>
        <b1 className="resetLinkSentDesc">Please check your email for instructions on resetting your password.</b1>
      </div>
    )
  }

  return (
    <div className='forgotPasswordContainer'>
        <img src={balloon} className='balloonImage'/>
        <h1 className='forgotPasswordTitle'>Forgot Password?</h1>
        <h3 className='loginSubtitle'>We will email you instructions to reset your password.</h3>
        <TextField 
            label="Email"
            sx={{width: {xs: 200, sm: 300, md: 400, lg: 500},
                marginBottom: 8,
                marginTop: 2,
                }}
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
            error={showEmailError}
            helperText={emailErrorMsg}
        />
        <div className='forgotButtons'>
            <Link className="sendEmail" onClick={handleSubmit}>Send Email</Link>
            <Link className='login' to="/login">Login</Link>
        </div>
    </div>
    
  );
}

export default ForgotPassword;

