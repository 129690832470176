import React from 'react';
import "../format.css";
import userImage from '../images/user.png';
import aiImage from "../images/fairOpp.jpg";


export default function qaResponse({ question, answer, index, navigateToSource}) {
  return (
    <React.Fragment key={index}>
      <div className="user">
        <img src={userImage} alt="User" className="userPic" />
        <b1 className="question">{question}</b1>
      </div>
      {answer && (
        <div className="ai">
          <img src={aiImage} alt="AI" className="aiPic" />
          
          <div>
            <b1 className="answer">{answer}</b1>
            <button className="sourceButton" onClick={() => navigateToSource(index)}>Learn More</button>
          </div>
        </div>
      )}
  
    </React.Fragment>
  )
}