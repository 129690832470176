import * as React from 'react';
import { useState, useEffect , useRef } from 'react';
import "../format.css";
import QaResponse from "../components/qaResponse.js";
import userImage from '../images/user.png';
import aiImage from "../images/fairOpp.jpg";
import loading from "../images/typing.gif";
import Alert from "@mui/material/Alert";
import TextareaAutosize from 'react-textarea-autosize';
import {v4 as uuidv4} from 'uuid';
import { formLabelClasses } from '@mui/material';


function Chat(props) {
  const [errorMessage, setErrorMessage] = useState('Your request ran into an error, please try again')
  const [question, setQuestion] = useState('')
  const [sessionID, setSessionID] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const [questionsToPrint, setQuestionsToPrint] = useState([])
  const [answersToPrint, setAnswersToPrint] = useState([])
  const [sourcesToPrint, setSourcesToPrint] = useState([])
  const [imagesToPrint, setImagesToPrint] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [convoStarted, setConvoStarted] = useState(true);
  const [backendError, setBackendError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(null);
  const inputRef = useRef(null);

  // Set up UUID for session
  useEffect(() => {
    setSessionID(uuidv4());
  }, []);

  // useEffect(() => {
  //   console.log(tokenValid)
  // }, [tokenValid]);

  useEffect(() => {
    if (loggedIn) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersToPrint, questionsToPrint]);

  // Delete user session before tab closes
  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      const id = sessionID.toString();
      fetch(props.backendURL + "delete/" + id, {
        method: "DELETE"
      });

    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  });

  const navigateToSource = (index) => {
    window.open(sourcesToPrint[index], '_blank');
  };

  // Handle user authentication
  useEffect(() => {
    let validToken = true;

    props.validateToken(props.backendURL)
    .then(response => {
      validToken = response

      if (!props.token || !validToken) {
        setTimeout(() => {
          props.removeToken()
          window.location.href = props.frontendURL + "login";  
          setLoggedIn(false);
        }, 800); 
      }
      else {
        setLoggedIn(true);
      }
    })
    .catch(error => {
      console.log(error)
    })    
  }, [props.token]);



  const handleSubmit = async (event) => {
    setConvoStarted(false)
    setBackendError(false)
    setQuestionsToPrint(questionsToPrint.concat(question))
    setQuestion("")

    event.preventDefault()

    setIsLoading(true)

    const endpoint = props.backendURL + "query/" + sessionID + "/" + question; 
    try {
      const response = await fetch(endpoint, {
        headers: {
          Authorization: 'Bearer ' + props.token
        }
      });
      if (response.status === 401) {
        setErrorMessage("Your session has expired or you are not logged in. Please log out and log back in.")
        setBackendError(true)
      }
      else if (response.status === 403) {
        setErrorMessage("Your session has expired. Please log out and log back in.")
        setBackendError(true)
      }
      else if (response.status === 500) {
        setErrorMessage("You have reached a maximum amount of requests in a time period. Come back in a few minutes.")
        setBackendError(true)
      }
      else if (!response.ok) {
        setErrorMessage("Your request ran into an error, please try again")
        setBackendError(true)
      }
      else {
        const data = await response.json();
        setChatHistory(chatHistory.concat([question, data.answer]));
        setImagesToPrint(imagesToPrint.concat(userImage, aiImage));
        setAnswersToPrint(answersToPrint.concat(data.answer));
        setSourcesToPrint(sourcesToPrint.concat(data.topSource));
      }
      
      setIsLoading(false);
    } catch (error) {
      // Display error. Set timeout for smoother UI experience 
      console.log(error)
      setTimeout(() => {
        setBackendError(true);
        setIsLoading(false);
      }, 1000); 
      
    } 
  }
  
  const handleChipClick = async (event, question) => {
    setQuestion(question)
  };

  // Submit form when user presses enter in textfield
  const onTextareaEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  // Logged in display
  if (loggedIn) {
    return (
      <> 
        <Alert severity="info"> Fin<font color="#5CB69E"><b>AI</b></font>d is currently trained with the updated 2024-25 FAFSA. Ask any question related to the FAFSA and get the most up to date answers. </Alert>
     
      <div className="main">        
        <div className="exchange">
        {questionsToPrint.map((question, index) => (
        <QaResponse 
          question={question}
          answer={answersToPrint[index]}
          index={index}
          navigateToSource={navigateToSource}
        />
        ))}

        
        {!isLoading && !convoStarted &&(
        <div className="expertButton">
          <h2 className="help">Want more personalized help?</h2>
          <a href="https://calendly.com/fairoppcarole/15min-1" target="_blank" className="expert">Talk to an Expert</a>
        </div>)}


        {isLoading && (
          <div className="ai">
            <img src={aiImage} alt="AI" className="aiPicLoading" />
            <img className="typingGIF" src={loading}></img>
          </div>
        )}
        
        {convoStarted && (
          <div className="chipMain">
            <div className='titles'>
              <h1 className="botTitle">Hi, I'm Finn, Fair Opportunity Project's Fin<font color="#5CB69E"><b>AI</b></font>d chatbot!</h1>
              <br></br>
              <h2 className="botSubtitle">I can answer questions about the FAFSA. Enter a question and hit the arrow to start a conversation.</h2>
            </div>
            <div className="buttonRow">
              <button type="button" onClick={(event) => handleChipClick(event, "What is the FAFSA form?")} className="chipButton">
                Why should I fill out a FAFSA?
              </button>
              <button type="button" onClick={(event) => handleChipClick(event, "Where can I find the changes made to the new FAFSA form?")} className="chipButton">
                When will I get my financial aid offer?
              </button>
              <button type="button" onClick={(event) => handleChipClick(event, "Where can I fill out the FAFSA form?")} className="chipButton">
                What's an FSA ID?
              </button>
            </div>
          </div>
        )}
        </div>
        
        {backendError && (
          <Alert severity="error"> {errorMessage} </Alert>
        )}

        <div className="container">
          <form onSubmit={handleSubmit}>
            <TextareaAutosize 
              type="submit"
              rows="1"
              placeholder="Enter your question"
              value={question}
              onChange={e => setQuestion(e.target.value)}
              ref={inputRef}
              className="field"
              onKeyDown={onTextareaEnterPress}
            />
            <button type="submit" className="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24"><path d="M21.426 11.095l-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909l-1.212 4.849a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z" fill="#5cb69e"></path></svg>
            </button>
          </form>
          <p className="disclaimer"> Disclaimer: This informational tool is trained to answer FAFSA-related questions. Responses are drawn exclusively from Federal Student Aid and National Association of Student Financial Aid Administrators guidance. That said, please consult your college’s financial aid office to confirm the accuracy of your FAFSA responses. <a className="learn-more-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdpDwCkZXeTsqe9lLptNe2Akp66VHKAcaGe7a2Hb40K5cznuw/viewform?usp=sf_link" target="_blank">Leave feedback</a>
          </p>
        </div>
      </div>
    </>
      
    );

  }
   // Logged in display
  else {
    return (
      <div className="chat-loading-container">  
        <img className="chat-loading-logo" src={aiImage}></img>
        <img className="chat-loading-dots" src={loading}></img> 
        <h2 className="chat-loading-h2">Checking if you are logged in... One moment please!</h2>
      </div>
  )
  }
}

export default Chat;

