import React from 'react';
import "../format.css"; 

const logoutButton = ({ token, onLogout }) => {
  return token ? (
      <div className="logout-button">
        {/* remove onClick so that the button does not disappear when clicked */}
        <p onClick={onLogout}>Log Out</p>
      </div>
  ): null;
};

export default logoutButton;
