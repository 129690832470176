import { useState } from 'react';

function useToken() {

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  async function validateToken(backendURL) {
    const response = await fetch(backendURL + "/validate_token", {
      method: "Post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({token: token})
    });
    const data = await response.json();

    if (response.ok) {
      return data.validated;
    }

    return false;
  }

  return {
    setToken: saveToken,
    token,
    removeToken,
    validateToken
  }

}

export default useToken;