import React from 'react';
import logo from "../images/fairOppWithLogo.png";
import LogOut from './logoutButton.js';
import "../format.css"; 

export default function Header (props){
  async function handleLogout() {
    try {
      const response = await fetch(props.backendURL + "logout", {
        method: "POST",
      })

      const data = await response.json();
      if (response.status === 200){
        props.removeToken()

        window.location.href = props.frontendURL + "login"
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    }
  }

  return (
    <header className="header">
      <div className="logo">
        <a href="https://www.fairopportunityproject.org/" target="_blank">
            <img className="header-fairOppLogo" src={logo} alt="Fair Opportunity Project" />
        </a>
      </div>
      <div className = "side-by-side-buttons"> 
          <a className='about-us' href='https://www.fairopportunityproject.org/finaid' target="_blank">About</a>
        <LogOut token={props.token} onLogout={handleLogout}/>
      </div>
    </header>
  );
};


