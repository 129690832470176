import React, { useState, useEffect } from 'react';
import "../format.css";
import balloon from "../images/fairOpp.jpg"
import { Link } from 'react-router-dom';
import { TextField, InputAdornment, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSearchParams } from "react-router-dom";


function ResetPassword(props) {
    const [showNewPasswordError, setShowNewPasswordError] = useState(false);
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [newEye, setNewEye] = useState(false);
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmEye, setConfirmEye] = useState(false);
    const [queryParameters] = useSearchParams();
    const [validURL, setValidURL] = useState(null);
    const [email, setEmail] = useState("");
    const [successfulReset, setSuccessfulReset] = useState(null)



    useEffect(() => {
      const validateToken = async () => {
        const token = queryParameters.get("token")

        try {
          const response = await fetch(props.backendURL + "validate_reset_password_token", {
            method: "Post",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({token: token})
          })
          const data = await response.json()

          if (response.ok) {
            setValidURL(true)
            setEmail(data.email)
          }
          else {
            setValidURL(false)
          }
        } catch (error) {
          setValidURL(false)
          setShowNewPasswordError(true);
          setShowConfirmPasswordError(true);
          setNewPasswordErrorMsg("Unforseen error. Please try again later.");
          setConfirmPasswordErrorMsg("Unforseen error. Please try again later.")
        }
      }
  
      validateToken()
    }, []);  


    async function handleSubmit (event) {
      event.preventDefault();
      setShowNewPasswordError(false);
      setShowConfirmPasswordError(false);
      setNewPasswordErrorMsg("");
      setConfirmPasswordErrorMsg("");

      try {
        const formData = {
          token: queryParameters.get("token"),
          password: newPassword,
          confirmPassword: confirmPassword
        }
        const response = await fetch(props.backendURL + "reset_password", {
          method: "POST", 
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formData)
        });
  
        const data = await response.json();

        if (response.ok) {
          window.location.href = props.frontendURL + "login"
        }
        else {
          setSuccessfulReset(false)
          if (data.new_password_error) {
            setShowNewPasswordError(true);
            setNewPasswordErrorMsg(data.msg);
          }
          if (data.confirm_password_error) {
            setShowConfirmPasswordError(true);
            setConfirmPasswordErrorMsg(data.msg)
          }
          if (!data.valid_URL) {
            setValidURL(false)
          }
          if (!data.new_password_error && !data.confirm_password_error) {
            setShowNewPasswordError(true);
            setShowConfirmPasswordError(true);
            setNewPasswordErrorMsg("Unforseen error. Please try again later.");
            setConfirmPasswordErrorMsg("Unforseen error. Please try again later.");
          }
        }
      } catch (error) {
        
        if (error.response) {
          setShowNewPasswordError(true);
          setShowConfirmPasswordError(true);
          setNewPasswordErrorMsg("Unforseen error. Please try again later.");
          setConfirmPasswordErrorMsg("Unforseen error. Please try again later.")
        }     
      }
      
    };  

    const handleNewEye = () => {
        setNewEye(!newEye);
      }

    const updateNewPassword = (newPasswordValue) => {
    setNewPassword(newPasswordValue);
    if (newPasswordValue === "") {
        setShowNewPasswordError(true);
        setNewPasswordErrorMsg("New password required");
    } 
    else if (newPasswordValue !== confirmPassword) {
      setShowNewPasswordError(true);
      setNewPasswordErrorMsg("Passwords don't match");
      setShowConfirmPasswordError(true);
      setConfirmPasswordErrorMsg("Passwords don't match");
    } else {
        setShowNewPasswordError(false);
        setNewPasswordErrorMsg("");
        setShowConfirmPasswordError(false);
        setConfirmPasswordErrorMsg("");
    }
    }

    const handleConfirmEye = () => {
        setConfirmEye(!confirmEye);
      }

    const updateConfirmPassword = (confirmPasswordValue) => {
    setConfirmPassword(confirmPasswordValue);
    if (confirmPasswordValue == "") {
        setShowConfirmPasswordError(true);
        setConfirmPasswordErrorMsg("Please confirm your new password");
    } else if (confirmPasswordValue !== newPassword) {
        setShowConfirmPasswordError(true);
        setConfirmPasswordErrorMsg("Passwords don't match");
        setShowNewPasswordError(true);
        setNewPasswordErrorMsg("Passwords don't match");
    } else {
        setShowConfirmPasswordError(false);
        setConfirmPasswordErrorMsg("");
        setShowNewPasswordError(false);
        setNewPasswordErrorMsg("");
    }}


  if (validURL === true) {
    return (
      <div className='forgotPasswordContainer'>
          <img src={balloon} className='balloonImage'/>
          <h1 className='forgotPasswordTitle'>Reset Password</h1>
          <h3 className='loginSubtitle'>Type in your new password below.</h3>
          <TextField 
              label="New Password"
              type={newEye ? "text" : "password"}
              sx={{width: {xs: 200, sm: 300, md: 400, lg: 500},
                  marginBottom: 5,
                  marginTop: 5}}
              value={newPassword}
              onChange={(e) => updateNewPassword(e.target.value)}
              error={showNewPasswordError}
              helperText={newPasswordErrorMsg}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={handleNewEye}>
                    {newEye?<VisibilityIcon/>:<VisibilityOffIcon/>}
                  </IconButton>
                </InputAdornment>
              }}
              />
          <TextField 
              label="Confirm Password"
              type={confirmEye ? "text" : "password"}
              sx={{width: {xs: 200, sm: 300, md: 400, lg: 500},
                  marginBottom: 5
              }}
              value={confirmPassword}
              onChange={(e) => updateConfirmPassword(e.target.value)}
              error={showConfirmPasswordError}
              helperText={confirmPasswordErrorMsg}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={handleConfirmEye}>
                    {confirmEye?<VisibilityIcon/>:<VisibilityOffIcon/>}
                  </IconButton>
                </InputAdornment>
              }}
              />
          <button className="loginButton" onClick={handleSubmit}> Reset Password </button>
      </div> 
    );
  }

  if (validURL === false) {
    return (
      <div className="resetLinkFailureMain">
        <img className="balloonImage" src={balloon}></img>
        <h1 className="resetLinkFailure">Invalid Reset Link!</h1>
        <b1 className="resetLinkFailureDesc">This password reset link is no longer valid. If you believe this is a mistake, please send yourself a new reset link</b1>
      </div>
    )
  }
}

export default ResetPassword;

