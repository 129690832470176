import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Chat from './pages/chat.js';
import useToken from './components/useToken';
import Header from './components/header.js';
import Login from './pages/login.js';
import SignUp from './pages/signUp.js';
import ForgotPassword from './pages/forgotPassword.js';
import ResetPassword from './pages/resetPassword.js';
import "./format.css"; 


function App() {
  const { setToken, token, removeToken, validateToken } = useToken();
  const backendURL = "https://fafsa-chatbot-api-a30508469f74.herokuapp.com/";
  //const backendURL = "http://127.0.0.1:5000/";
  const frontendURL = "https://chat.fairopportunityproject.org/";
  //const frontendURL = "http://localhost:3000/";


  return (
    <div> 
      <Header removeToken={removeToken} token={token} backendURL={backendURL} frontendURL={frontendURL}/>
        <Routes>
          <Route path="/" element={<Chat token={token} backendURL={backendURL} frontendURL={frontendURL} validateToken={validateToken} removeToken={removeToken} />} />
          <Route path="/login" element={<Login setToken={setToken} backendURL={backendURL} frontendURL={frontendURL} />} />  
          <Route path="/resetpassword" element={<ResetPassword backendURL={backendURL} frontendURL={frontendURL}/>} />
          <Route path="/signup" element={ <SignUp backendURL={backendURL} setToken={setToken} frontendURL={frontendURL}/>} />
          <Route path="/forgotpassword" element={ <ForgotPassword backendURL={backendURL}/> }/>
        </Routes>
    </div>
  );
}

export default App;